* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #ffffff;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.w100 {
  width: 100%;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.p0 {

  padding: 0 !important;

}

.pl0 {
  padding-left: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.p20 {
  padding: 20px !important;
}

.borderLeft {
  border-left: 1px solid #C6C7C8;
}

.textRight {
  text-align: right !important;
}

.query-builder {
  margin: 0 !important;
}

.query-builder {
  .group {
    background: #e7effe;
    border: 1px solid #6ea0f7;
    padding: 15px 20px;

    .group--conjunctions {
      margin-left: 0;
    }

    .group-or-rule-container {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 0;
    }
  }

  .rule--value {
    margin-left: 10px;

    input {
      height: auto;
    }
  }

  .rule--operator {
    margin-left: 20px;
  }

  .group--children>.group-or-rule-container>.group-or-rule::before,
  .group--children>.group-or-rule-container>.group-or-rule::after {
    content: "";
    border-color: #0b4ec1;
    left: -15px;
    width: 15px;
  }

  .group--children.hide--line>.group-or-rule-container>.group-or-rule::before,
  .group--children.hide--line>.group-or-rule-container>.group-or-rule::after {
    border-color: #0b4ec1;
  }
}

.singleBlockCarousel {
  button+div {
    div {
      div:nth-child(1) {
        background: #eaf6ed;

        h5 {
          color: #24a148;
        }
      }

      div:nth-child(2) {
        background: #fbe8e9;

        h5 {
          color: #e01f29;
        }
      }

      div:nth-child(3) {
        background: #fef6e7;

        h5 {
          color: #c1810b;
        }
      }
    }
  }
}

.MuiAccordionDetails-root {
  display: inherit !important;
}

.dialogProgressbar {
  .colorBar {
    height: 100%;
    border-radius: inherit;
    text-align: center;

    span {
      padding: 0 10px;
      font-size: 14px;
      position: absolute;
      top: 9px;
      left: 0;
    }
  }

  .valStyles {
    position: absolute;
    top: 9px;
    right: 6px;
    font-size: 10px;
    display: inline-block;
    color: #404041;
  }
}

.liftStyles {
  width: 25%;
  float: left;
  line-height: 3;
  padding: 0 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span+span {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  svg {
    font-size: 30px;
  }
}


/**************************--------Design-Capmaign Module---------------******************** */

.w-100 {
  width: 100%;
}

.insightCardHeader {
  padding: 10px;
}

.carouselSquareDots {
  height: 8px;
  width: 8px;
  border-radius: 2px;
  background-color: rgb(209, 209, 209);
}

/* ______________________________________________________________________________________ */
.floatingField {
  position: relative;

  .floatingLabel {
    color: #929497;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 9px;
    top: 17px;
    background: #fff;
    padding: 0 5px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .floatingInput {
    font-size: 14px;
    display: block;
    width: 100%;
    height: 54px;
    padding: 0 14px;
    background: #fff;
    color: #929497;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    box-sizing: border-box;

    &:focus {
      outline: none;
      border: 2px solid #182C52;
    }
  }

  .floatingInput:focus~.floatingLabel,
  .floatingInput.active~.floatingLabel,
  input.flatpickr-input:not([value=""])~.floatingLabel {
    top: -3px;
    transform: translate(14px, -6px) scale(0.75);
    left: -19px;
  }

  .floatingInput:focus~.floatingLabel {
    color: #182C52;
    font-weight: 500;
  }

  &:hover {
    .floatingInput {
      border: 1px solid #929497;
    }
  }
}

// tooltip css
// .timelineChart .apexcharts-tooltip {
//   color: rgb(194, 53, 53);
// }

// .timelineChart .apexcharts-tooltip .apexcharts-tooltip-series-group.active {
//   background: blue !important;
// }
//  .apexcharts-tooltip {
//     // color: red;
//     padding:20px !important;
//     li{
//       list-style: none;
//       padding: 0 0 5px 0;
//     }

//   }
// //   .apexcharts-tooltip  li {
// // text-decoration: none;
// //   }

//  .apexcharts-tooltip .apexcharts-tooltip-series-group.active {
//     background: blue !important;
//   }

.asyncInput span+span+div {
  min-height: 53px;
  box-shadow: 0 0 0 0 #B3BAC5;
  border-color: #B3BAC5;
}

.asyncInput span+span+div:hover {
  box-shadow: 0 0 0 1px #182C52;
  border-color: #182C52;
}

#react-select-2-listbox {
  z-index: 4;

  div {
    div:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    div:active {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
}

.autoCompleteArea {
  .MuiAutocomplete-tag.MuiChip-root {
    margin: 0;
    background: transparent;
    color: inherit;
    height: auto;
    text-align: left;
  }
}

/**************************--------Sleek Scrollbar---------------******************** */
.tblScroll div div div::-webkit-scrollbar {
  width: 12px;
}

.tblScroll div div div::-webkit-scrollbar-track {
  background: #f2f2f2;
}

.tblScroll div div div::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  border-radius: 20px;
  border: 3px solid #f2f2f2;
}

.sectionScroll {
  height: 250px;
  overflow-y: scroll
}

.sectionScroll::-webkit-scrollbar {
  width: 12px;
}

.sectionScroll::-webkit-scrollbar-track {
  background: #f2f2f2;
}

.sectionScroll::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  border-radius: 20px;
  border: 3px solid #f2f2f2;
}