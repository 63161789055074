.arrow {
  position: absolute;
  top: 50%;
  outline: none;
  color: #182C52 !important;
  background: #ffffff !important;
  border-radius: 50%;
  border: 1px solid #ccc !important;
  font-size: inherit !important;
  height: 25px;
  width: 25px;
  padding: 0;
  cursor: pointer;
}

.right {
  right: 0;
  outline: none;
}

.left {
  left: 0;
}

.left_arrow {
  width: 45px;
  z-index: 200;
  outline: none;
  transform: rotateZ(180deg);
  user-select: none;
  cursor: pointer;
}

.right_arrow {
  width: 45px;
  z-index: 200;
  outline: none;
  user-select: none;
  cursor: pointer;
}

button:focus {
  outline: none;
}

@media only screen and (max-width: 600px) {
  .left_arrow {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .right_arrow {
    display: none;
  }
}